const v8Formatter = {
  v8Forms: document.querySelectorAll('.v8Form__form'),

  init: function () {
    for (const form of this.v8Forms) {
      const logicFields = form.querySelectorAll('[data-logic]');
      this.showConditionalFields(logicFields, form);
      this.customRadio(form);

      const phoneInput = form.querySelector('input[type="tel"]');
      phoneInput.addEventListener('keyup', this.formatPhoneField(phoneInput));
      this.addInlineCalendar(form);
      this.formatFileUpload(form);
    }

    // Get Referrer and add to hidden field
    this.getReferrer();
  },

  formatPhoneField(element) {
    return function (event) {
      if (!element.value) {
        return element.value;
      }

      let phoneNumber = element.value;
      const phoneNumberLength = phoneNumber.length;

      const cleaned = ('' + phoneNumber).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        event.target.value = '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    };
  },

  // Logic only works with checkbox and radio fields
  showConditionalFields(logicFields, form) {
    for (const field of logicFields) {
      // Display None by Default
      field.style.display = 'none';

      const logicArray = field.dataset.logic.split('-');
      const input = form.querySelector(`[name="input_${logicArray[0]}"]`);
      const choice = input.parentElement.parentElement.querySelector(
        `[value="${logicArray[2]}"]`
      );

      // Checkbox Logic
      if (input.type === 'checkbox') {
        choice.addEventListener('change', function (event) {
          if (this.checked) {
            field.style.display = 'inline-block';
          } else {
            field.style.display = 'none';
          }
        });
      }

      // Radio Logic
      if (input.type === 'radio') {
        choice.addEventListener('change', function (event) {
          let checkedInput = input.parentElement.querySelector(
            `input[type=radio]:checked`
          );

          if (choice.checked) {
            field.style.display = 'inline-block';
          } else {
            field.style.display = 'none';
          }
        });
      }
    }
  },

  customRadio: function (form) {
    form.addEventListener('click', function (event) {
      if (event.target.classList.contains('v8Fields__radio-single')) {
        for (const single of event.target.parentElement.children) {
          single.classList.remove('v8-radio-selected');
          const falseInput = single.querySelector('input');
          falseInput.checked = false;
        }
        event.target.classList.add('v8-radio-selected');
        const trueInput = event.target.querySelector('input');
        trueInput.checked = true;
      }
    });
  },

  formatFileUpload: function (form) {
    const uploadInputs = form.querySelectorAll('input[type="file"]');

    for (const input of uploadInputs) {
      const fileReader = new FileReader();
      const preview = input.parentElement.querySelector(
        '.v8Fields__fileupload-preview'
      );

      const close = input.parentElement.querySelector(
        '.v8Fields__fileupload-preview--close'
      );

      fileReader.addEventListener(
        'load',
        function (event) {
          const image = new Image();
          preview.style.backgroundImage = `url(${fileReader.result})`;
        },
        false
      );

      input.addEventListener('change', function (event) {
        if (event.target.files[0].type.search('image') !== -1) {
          fileReader.readAsDataURL(event.target.files[0]);
        } else if (event.target.files[0].type === 'application/pdf') {
          const icon = document.createElement('fa-icon');
          icon.setAttribute('icon', 'file-pdf');
          icon.style.fontSize = '30px';
          icon.style.textAlign = 'center';
          icon.style.padding = '5% 0';
          icon.style.display = 'inherit';
          preview.appendChild(icon);
        }
      });

      close.addEventListener('click', function (event) {
        input.value = null;
        preview.style.backgroundImage = '';
      });
    }
  },

  getReferrer() {
    const ref = document.querySelector('.v8__referrer');
    ref.value = document.referrer;
  },

  addInlineCalendar: function (form) {
    const input = form.querySelector('.v8-inline-calendar > input');

    if (!input) {
      return;
    }

    const config = {
      inline: true,
      minDate: new Date(),
      dateFormat: 'Y-m-d',
      allowInput: true,
      onChange: async function (selectedDate, dateStr, instance) {
        const formId = form.querySelector('[name=gf_form_id]').value;

        // Return if dynamic scheduling feature not set up
        if (!form.querySelector('.v8-dynamic-slots')) {
          return;
        }

        const dynamicSlots = await form.querySelector(
          '.v8-dynamic-slots > .v8Fields__radio-container'
        );

        // Set up and add the loading spinner
        const spinner = document.createElement('div');
        spinner.classList.add('v8-spinner');
        spinner.innerHTML = `<div class="v8-spinner-container"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;
        spinner.style.backgroundColor = 'rgba(255,255,255,0)';
        spinner.firstChild.style.backgroundColor = '#333';
        spinner.firstChild.style.width = '85px';
        spinner.firstChild.style.height = '85px';
        dynamicSlots.appendChild(spinner);

        // Otherwise do the logic
        const promise = await fetch(
          `${wpRest.siteUrl}/wp-content/plugins/lnb-v8-scheduler/src/api/st-cap-lookup.php?frontEndLookup=true&date=${dateStr}&formId=${formId}`
        );

        // Remove the loading spinner
        spinner.remove;

        const response = await promise.json();
        console.log(response);
        const body = JSON.parse(response.body);
        const avblties = body.Availabilities;
        console.log(body);

        const startingInputName = dynamicSlots.querySelector('input').name;

        const inputArray = [];
        const spanArray = [];

        // First Loop to check if there no availablilies.
        let totalAvail = 0;
        const testInput = form.querySelector('.flatpickr-input');
        console.log(testInput);
        for (const slot of avblties) {
          totalAvail = +slot.TotalAvailability;
        }

        for (const slot of avblties) {
          if (dynamicSlots) {
            while (dynamicSlots.firstChild) {
              dynamicSlots.removeChild(dynamicSlots.lastChild);
            }

            const start = new Date(slot.Start);
            const end = new Date(slot.End);
            var options = {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            };
            const startPretty = start.toLocaleString('en-US', options);
            const endPretty = end.toLocaleString('en-US', options);
            options.hour12 = false;

            const input = document.createElement('input');
            input.type = 'radio';
            input.value = start.toLocaleString('en-US', options);
            input.name = startingInputName;
            await inputArray.push(input);

            const span = document.createElement('span');
            span.innerText = `${startPretty} - ${endPretty}`;
            if (slot.OpenAvailability === 0) {
              span.dataset.available = false;
            }
            await spanArray.push(span);
          } else {
            throw new Error('No Dynamic Slot Class Found');
          }
        }

        if (inputArray.length !== 0) {
          let i;

          for (i = 0; i < inputArray.length; i++) {
            const parent = document.createElement('div');
            parent.classList.add('v8Fields__radio-single');

            if (spanArray[i].dataset.available === 'false') {
              parent.classList.add('v8-radio-unavailable');
              parent.title = 'Time Unavailable';
            }

            dynamicSlots.appendChild(parent);
            parent.appendChild(inputArray[i]);

            parent.appendChild(spanArray[i]);
          }
        } else {
          dynamicSlots.innerHTML === 'No Times available on this day';
        }
      },
    };

    this.calendar = flatpickr(input, config);
    const flatpickrInput = form.querySelector('.flatpickr-input');
    flatpickrInput.style.display = 'none';
  },
}.init();
